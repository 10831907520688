import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import * as styles from '../blogs.module.scss';
import BlogImg from '../../../../assets/IMG/blog/18-03-2022.png';
import ReCAPTCHA from "react-google-recaptcha";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { API_AddToNewsLetter, API_BaseURL } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faSpinner } from '@fortawesome/free-solid-svg-icons';

interface Props {
  location: string;
}

const EmploymentLaw18032022: React.FC<Props> = ({ location }) => {
  const shareURL: string = location ? `${(API_BaseURL === "/api/" && !location.toLowerCase().includes("beta") ? "https://primehr.co.uk/" : "https://beta.primehr.co.uk/")}Blog/Employment-Law-18-03-2022` : "";
  const [email, setEmail] = useState<string>("");
  const [captcha, setCaptcha] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const Join = async () => {
    setLoading(true);

    if (captcha && email) {
      await Fetch(API_AddToNewsLetter, email).then((Success: boolean) => {
        if (Success) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            setEmail("");
          }, 3000);
        }
      })
    }

    setLoading(false);
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Blog :: Employment Law 18-03-2022</title><meta charSet='utf-8' />
        <meta property="og:description" content="Wales online reports a successful compensation claim of £115,657.50 for a charity worker at Mind who left because of work place bullying after they blew the whistle!" />
        <meta property="og:image" content={BlogImg} />
        <meta property="og:title" content="PrimeHR :: Blog :: Employment Law 18-03-2022" />
        <meta property="og:site_name" content="PrimeHR" />
        <meta property="og:url" content={shareURL} />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Blog", Text: "Blog" },
        { To: "", Text: "Employment Law 04-03-2022" }
      ]} />

      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>Employment Law - Policy, Case Law & Updates</h1>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            <span>Published On {new Date(2022, 2, 4).toDateString()}</span>
          </div>

          <div className={styles.SectionImage}>
            <img loading={"lazy"} src={BlogImg} />
          </div>

          <h3>How much does discrimination cost?</h3>

          <p>
            Wales online reports a successful compensation claim of £115,657.50 for a charity worker at Mind who left because of work place bullying after they blew the whistle!
          </p>
          <p>
            It continues:
          </p>
          <p>
            "The employment tribunal heard Miss Robinson blew the whistle after seeing a co-worker do an offensive impression of a physically disabled woman."
          </p>
          <p>
            "Miss Robinson said she was so upset by the mimicking that she broke down in tears as other co-workers laughed."
          </p>
          <p>
            "The Tribunal was told that during a meeting, one worker described the impression as "harmless", adding that "It was banter, a bit of fun".
          </p>
          <p>
            "Miss Robinson told the hearing she was subjected to "blatant bullying" in the office after she complained about the impression."
          </p>
          <p>
            "Miss Robinson later resigned from her job claiming she was constructively dismissed."
          </p>
          <p>
            The Tribunal held:
          </p>
          <p>
            "She blew the whistle on an incident which was admitted by the management involved in it. Despite this, it was not escalated."
          </p>
          <p>
            "The management swept the matter under the carpet to save their own involvement in it. In so doing they failed to consider their duty of care to her."
          </p>
          <p>
            "The lack of management or escalation led to a situation where the Claimant was ostracised and the behaviour within the office was not kept in check."
          </p>
          <p>
            "There was frequent bad language and banter which overstepped the boundaries of acceptability in terms of equality and diversity."
          </p>
          <p>
            "There was reported to have been racially and sexually offensive language and comments made that were derogatory to people with mental and physical disabilities."
          </p>

          <h3>Are your people affected by the Ukraine crisis?</h3>

          <p>
            With the effects of Covid still being felt by our work forces you may find more colleagues having difficulties coping with the war in the Ukraine. As the conflict unfolds an individuals mental toughness may waver and they could need extra support or counselling from managers, mental health first aider's and EAP services.
          </p>
          <p>
            Don't under estimate how people are feeling and don't shy away from the conversation.
          </p>

          <h3>Firing Your Workforce via Zoom?</h3>

          <p>
            We get that businesses have to make some tough decisions, particularly post Covid, but apart from the legal risks there is a human cost if not done right.
          </p>
          <p>
            Over the past two years delivering a difficult message to folk over remote platforms has been the norm however it does require some detailed consideration and preparation beforehand.
          </p>
          <p>
            There are issues of psychological safety, emotional support and clear planned communication that needs working through prior to launching such a significant change project.
          </p>
          <p>
            P&O with 800 staff severely underestimated the impact and have hid behind marine law to minimise legal challenges and will no doubt gag many disgruntled employees through enhanced payouts.
          </p>
          <p>
            Morrison Supermarkets, after being bought by Private Equity last year, used zoom to tell all its middle managers they're services were no longer required, asked them to leave the meeting (and the stores they were located in) then introduced the new regional team.
          </p>
          <p>
            It doesn't matter what payout or litigation follows, can you just imagine the journey home when the shock sets in?
          </p>
          <p>
            We know that people can be severely effected for years after being made redundant when they are simply discarded and treated as a number on a spreadsheet.
          </p>
          <p>
            There is a better way so if you are a 'considerate Leader' and you have some tough decisions to make then you know where we are.
          </p>
        </div>
        <div className={styles.SectionSide}>
          <div className={styles.SectionSideInfo}>
            <h4>Keep Up To Date</h4>
            <p>If you find our blog updates useful then consider subscribing to our newsletter for regular updates on UK Employment Law</p>

            <label>
              <span>Your Email:</span>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email..." />
            </label>

            <div className="capcha">
              <ReCAPTCHA
                onExpired={() => { setCaptcha("") }}
                onErrored={() => { setCaptcha("") }}
                sitekey="6Lf-XiQaAAAAAI5mHpzBUc6vtyvEWTS17PLcGnWT" onChange={e => { setCaptcha(e); }} />
            </div>

            <div className={styles.Download}>
              <button className={success ? styles.Green : ""} disabled={!captcha || !email} type="button" onClick={() => Join()}>{loading ? <><i><FontAwesomeIcon icon={faSpinner} spin={true} /></i> Joining...</> : success ? <><i><FontAwesomeIcon icon={faCheckDouble} /></i> Successfully Joined!</> : "Join Newsletter"}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmploymentLaw18032022;