import React from 'react';
import Layout from '../../components/Layout/layout';
import EmploymentLaw18032022 from '../../Views/Website/Blog/EmploymentLaw18032022/EmploymentLaw18032022';

const EmploymentLaw18032022Page: React.FC = (props: any) => {
  return (
    <Layout>
      <EmploymentLaw18032022 location={props.location.href} />
    </Layout>
  );
};

export default EmploymentLaw18032022Page;
